import React from 'react';
import { Text, Link, View, StyleSheet } from '@react-pdf/renderer';

const Header = () => {
  return (
    <View>
      <Text style={styles.header} fixed>
        Abdelrahman Jan ( A.J. ) Makhl
      </Text>
      <Link style={styles.headerLinks} src={'www.linkedin.com/in/ajmakhl'}>
        <Text>www.linkedin.com/in/ajmakhl</Text>
      </Link>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    color: '#1f1f1f',
    marginLeft: 12,
    marginBottom: 5,
  },
  headerLinks: {
    fontSize: 12,
    color: '#26a0f4',
    marginLeft: 12,
    marginBottom: 15,
  },
});
