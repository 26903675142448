import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const Category = (props) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.line} />
      <Text style={styles.text}>{props.category}</Text>
      <View style={styles.line2} />
    </View>
  );
};

export default Category;

const styles = StyleSheet.create({
  wrapper: {
    height: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textWrap: {
    backgroundColor: 'red',
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#5f5f5f',
    margin: 0,
    textAlign: 'center',
    width: '15%',
  },
  line: {
    width: '15%',
    height: 3,
    backgroundColor: '#5f5f5f',
    borderRadius: 10,
  },
  line2: {
    width: '65%',
    height: 3,
    backgroundColor: '#5f5f5f',
    borderRadius: 10,
  },
});
