import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const ToolBelt = (props) => {
  return (
    <View style={styles.skillWrapper}>
      <Text style={styles.skill}>
        1. Proficiency in languages such as Typescript, Javascript, HTML, and
        CSS.
      </Text>
      <Text style={styles.skill}>
        2. Experience with programming tools such as React JS, Node JS and AWS
        Serverless Services.
      </Text>
      <Text style={styles.skill}>
        3. Commitment to developing maintainable and scalable code based on
        SOLID principles.
      </Text>
      <Text style={styles.skill}>
        4. Strong problem-solving and analytical skills.
      </Text>
    </View>
  );
};

export default ToolBelt;

const styles = StyleSheet.create({
  skillWrapper: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
  },
  skill: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    color: '#1f1f1f',
    margin: 5,
    marginLeft: 12,
    // textAlign: 'center',
  },
});
