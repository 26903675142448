import React from 'react';
import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import Header from './components/Headers';
import Summary from './components/Summary';
import Stardust from './jobs/Stardust';
import Remedi from './jobs/Remedi';
import globalStyles from './globalStyles';
import VisualMenu from './jobs/VisualMenu';
import Category from './components/Category';
import ToolBelt from './components/ToolBelt';

const Doc = () => (
  <Document>
    <Page style={styles.body}>
      <Header />
      <Summary />
      <Category category="Experience" />
      <View style={globalStyles.space} />
      <Stardust />
      <View style={globalStyles.space} />
      <Remedi />
      <View style={globalStyles.space} />
      <Category category="Projects" />
      <View style={globalStyles.space} />
      <VisualMenu />
      <View style={globalStyles.space} />
      <Category category="Tool Belt" />
      <View style={globalStyles.space} />
      <ToolBelt />
    </Page>
  </Document>
);

export default Doc;

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
});
