import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer, Font } from '@react-pdf/renderer';
import './styles.css';
import Document from './Document';
import DownloadLink from './DownloadLink';

Font.registerHyphenationCallback((word) => [word]);

const App = () => (
  <PDFViewer className="my-pdf" width="100%" showToolbar={false}>
    <Document />
  </PDFViewer>
);

ReactDOM.render(
  <main>
    <App />
    <div className="menu">
      <DownloadLink />
    </div>
  </main>,
  document.getElementById('root')
);
