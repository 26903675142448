import React from 'react';
import globalStyles from '../globalStyles';
import { Text, View } from '@react-pdf/renderer';

const Stardust = () => {
  return (
    <View>
      <Text style={globalStyles.title}>
        Software Engineer{' '}
        <Text style={globalStyles.lightTitle}>- Stardust, Palo Alto</Text>
      </Text>
      <Text style={globalStyles.subtitle}>
        Since 2020 ({new Date().getFullYear() - 2020} Years)
      </Text>
      <Text style={globalStyles.bullets}>
        Benefited to the company's ability to secure $30 million in funding and
        expand its team from 3 employees to 30 plus.
      </Text>
      <Text style={globalStyles.bullets}>
        Played a crucial role in building, maintaining, and deploying a number
        of systems of front-end and back-end that contributed to the success of
        the company.
      </Text>
      <Text style={globalStyles.bullets}>
        Architected and developed 150+ RESTful APIs for a blockchain-based
        platform, enabling seamless communication between public and private
        front-end and back-end systems.
      </Text>
      {/* <Text style={globalStyles.bullets}>
        Contributed to the development of best practices and standards for API
        design and development within the company.
      </Text> */}
    </View>
  );
};

export default Stardust;
