import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Document from './Document';

const DownloadLink = () => (
  <div>
    <PDFDownloadLink
      className="pdf-download-link"
      document={<Document />}
      fileName="somename.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download now!'
      }
    </PDFDownloadLink>
  </div>
);

export default DownloadLink;
