import { StyleSheet } from '@react-pdf/renderer';

const globalStyles = StyleSheet.create({
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
    color: '#1f1f1f',
    marginLeft: 12,
  },
  lightTitle: {
    fontFamily: 'Helvetica',
    fontSize: 14,
    color: '#1f1f1f',
  },
  titleSideProject: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 15,
    color: '#3f3f3f',
    marginLeft: 12,
  },
  subtitle: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#5f5f5f',
    margin: 0,
    marginTop: 3,
    marginLeft: 12,
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#1f1f1f',
    margin: 12,
  },
  bullets: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#1f1f1f',
    margin: 12,
    marginTop: 7,
    marginBottom: 0,
    paddingLeft: 12,
    borderLeftWidth: 1,
    borderLeftColor: '#26a0f4',
    borderLeftStyle: 'solid',
    display: 'list-item',
  },
  space: {
    height: 10,
  },
});

export default globalStyles;
