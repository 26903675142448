import React from 'react';
import globalStyles from '../globalStyles';
import { Text, View } from '@react-pdf/renderer';

const Summary = () => {
  return (
    <View>
      <Text style={globalStyles.title}>Summary</Text>
      <Text style={globalStyles.text}>
        As a Full-Stack Software Engineer with a successful track record, I
        excel at researching and analyzing cross-platform applications. I have a
        solid ability to create user-friendly and developer-friendly solutions
        that are elegantly responsive. My attention to detail and passion for
        effective engineering architecture make me a valuable asset to any
        organization. My skills and experience qualify me as a top-performing
        engineer and an excellent fit for any team.
      </Text>
    </View>
  );
};

export default Summary;
