import React from 'react';
import globalStyles from '../globalStyles';
import { Text, View } from '@react-pdf/renderer';

const VisualMenu = () => {
  return (
    <View>
      <Text style={globalStyles.titleSideProject}>
        Frontend Developer{' '}
        <Text style={globalStyles.lightTitle}>- A Visual Menu, Atlanta</Text>
      </Text>
      <Text style={globalStyles.subtitle}>
        Side Project Since 2018 ({2019 - 2018} years)
      </Text>
      <Text style={globalStyles.bullets}>
        Conduct research to develop a standardized mobile visual food menu using
        QR code and NFC technology as a means to enhance and/or eliminate the
        use of paper menus.
      </Text>
      <Text style={globalStyles.bullets}>
        Identify and implement the most effective UI/UX designs for a
        user-friendly mobile visual food menu that can be easily navigated and
        accessed by customers in a variety of restaurants.
      </Text>
      <Text style={globalStyles.bullets}>
        Develop an easy-to-use dashboard for customers to upload their
        restaurant's food menus, allowing for efficient and convenient
        management of menu updates and changes.
      </Text>
    </View>
  );
};

export default VisualMenu;
