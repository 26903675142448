import React from 'react';
import globalStyles from '../globalStyles';
import { Text, View } from '@react-pdf/renderer';

const Remedi = () => {
  return (
    <View>
      <Text style={globalStyles.title}>
        Web Developer{' '}
        <Text style={globalStyles.lightTitle}>- Remedi, Atlanta</Text>
      </Text>
      <Text style={globalStyles.subtitle}>
        Since 2018 ({2020 - 2018} years)
      </Text>
      <Text style={globalStyles.bullets}>
        Successfully built and deployed web applications including dashboards
        and APIs that facilitate the efficiency and effective functionality of
        Remedi's medical internal systems.
      </Text>
      <Text style={globalStyles.bullets}>
        Designed and implemented various UI/UX components to ensure a
        user-friendly interface and enhance user experience for private web
        applications.
      </Text>
      <Text style={globalStyles.bullets}>
        Increased productivity and ease of use for specific needs and
        requirements that tailored to Remedi's day to day operations by
        integrating with various third-party tools and APIs.
      </Text>
    </View>
  );
};

export default Remedi;
